<template>
  <section>
    <h2>Administración de unidades de medida</h2>

    <!-- Botón para agregar nueva unidad de medida -->
    <v-btn solid color="secondary" @click="
      (dialog = true), (unidadMedida = { nombre: null })
      " class="mt-4">
      Agregar unidad de medida
    </v-btn>

    <!-- Dialogo para agregar nueva unidad de medida -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Agregar nueva unidad de medida</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="mt-4">
              <v-col cols="12">
                <v-text-field outlined v-model="unidadMedida.nombre" label="Nombre" required counter="150" :rules="[
                  v => !!v || 'El nombre es requerido',
                  v => (v && v.length <= 150) || 'El nombre debe tener menos de 50 caracteres',
                ]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="secondary" solid min-width="40%" @click="guardarUnidadMedida">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
    <v-row class="mt-4">
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          v-model="busqueda"
          label="Búsqueda"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Tabla de unidades de medida -->
    <v-data-table :search="busqueda" :headers="headers" :items="unidadesMedida" :items-per-page="5"
      class="elevation-1 mt-4">
      <template v-slot:item.actions="{ item }">
        <v-btn icon color="secondary" @click="editarUnidadMedida(item), (unidadMedida = item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="secondary" class="ml-2" @click="(showConfirmDelete = true), (unidadMedidaSelected = item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Dialogo para confirmar eliminación de unidad de medida -->
    <ConfirmationDialogComponent
      :show="showConfirmDelete"
      btnConfirmar="Eliminar"
      title="¿Desea eliminar la unidad de medida?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="showConfirmDelete = false"
      @confirm="eliminarUnidadMedida"
    />
  </section>
</template>

<script>
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";

export default {
  name: 'AdminUnidadesMedidaView',
  components: {
    ConfirmationDialogComponent,
  },
  data() {
    return {
      unidadMedida: {
        nombre: null,
      },
      busqueda: '',
      dialog: false,
      unidadMedidaSelected: null,
      unidadesMedida: [],
      headers: [
        {
          text: 'Nombre',
          align: 'left',
          sortable: false,
          value: 'nombre',
          width: '80%',
        },
        {
          text: 'Acciones',
          align: 'left',
          sortable: false,
          value: 'actions',
        },
      ],
      showConfirmDelete: false,
    }
  },
  methods: {
    async obtenerUnidadesMedida() {

      const response = await this.services.Presentations.getListPresentations()
      this.unidadesMedida = response.data

    },
    async guardarUnidadMedida() {

      const response = await this.services.Presentations.createPresentation(this.unidadMedida)

      if (response.status === 201 || response.status === 200) {
        this.obtenerUnidadesMedida();
        this.unidadMedida = { nombre: null };
        this.dialog = false;
      }

    },
    editarUnidadMedida(unidadMedida) {
      this.unidadMedidaSelected = unidadMedida
      this.dialog = true
    },
    async eliminarUnidadMedida() {

      const response = await this.services.Presentations.deletePresentation(this.unidadMedidaSelected.id)

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: 'Unidad de medida eliminada correctamente',
          type: 'success',
        })
        await this.obtenerUnidadesMedida()
        this.showConfirmDelete = false;
      }

    },
  },
  created() {
    this.obtenerUnidadesMedida()
  },
}
</script>